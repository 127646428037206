import * as yup from "yup";
import { validateText } from "shared/utils/yupValidation";
import { LoginFormValues } from "./types";

export const initLoginFormValue = (): LoginFormValues => {
  return {
    username: "",
    password: "",
  };
};

export const validateLoginSchema = () => {
  return yup.object().shape({
    username: validateText(3),
    password: validateText(3),
  });
};
