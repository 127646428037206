import axios, { Method } from "axios";
import { getToken } from "../localstorage";
import {
  AdminOrderStatusResponse,
  BlankRequest,
  ExportDataResponse,
  GetItemsResponse,
  GetPaidOrderRequest,
  GetPercentDiscountResponse,
  LoginRequest,
  LoginResponse,
  MyServerReponse,
  PaymentCompleteRequest,
  PaymentCompleteResponse,
  StatusResponse,
  SubmitOrderRequest,
  SubmitOrderResponse,
  TrackingRequest,
  TrackingResponse,
  UpdateOrderRequest,
  UpdateShipDateRequest,
} from "./type";

const SERVER_NAME = process.env.REACT_APP_SERVER_URL;

type RequestType = BlankRequest;

const callServer = (
  method: Method,
  url: string,
  data: RequestType
): Promise<any> => {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  return axios({
    method,
    data,
    url: `${SERVER_NAME}${url}`,
    ...config,
  });
};

const GET = "get";
const POST = "post";

export const getItems = (): Promise<MyServerReponse<GetItemsResponse>> => {
  return callServer(GET, "/mooncake", {});
};

export const getPercentDiscount = (
  data: BlankRequest
): Promise<MyServerReponse<GetPercentDiscountResponse>> => {
  return callServer(GET, "/discounts/latest", data);
};

// export const getShippingDate = (
//   data: BlankRequest
// ): Promise<MyServerReponse<GetShippingDateResponse>> => {
//   return callServer(GET, "/shipping/next", data);
// };

export const submitOrder = (
  data: SubmitOrderRequest
): Promise<MyServerReponse<SubmitOrderResponse>> => {
  return callServer(POST, "/mooncake/submitOrder", data);
};

export const paymentComplete = (
  data: PaymentCompleteRequest
): Promise<MyServerReponse<PaymentCompleteResponse>> => {
  return callServer(POST, "/mooncake/paymentComplete", data);
};

export const track = (
  data: TrackingRequest
): Promise<MyServerReponse<TrackingResponse>> => {
  if (data.orderNumber) {
    return callServer(GET, `/order/findByOrderNumber/${data.orderNumber}`, {});
  }
  return callServer(GET, `/order/findByEmail/${data.email}`, {});
};

export const login = (
  data: LoginRequest
): Promise<MyServerReponse<LoginResponse>> => {
  return callServer(POST, "/auth/login", data);
};

export const getOrder = (
  data: GetPaidOrderRequest
): Promise<MyServerReponse<AdminOrderStatusResponse>> => {
  return callServer(POST, "/admin/getOrder", data);
};

export const exportData = (
  data: GetPaidOrderRequest
): Promise<MyServerReponse<ExportDataResponse>> => {
  return callServer(POST, "/admin/exportData", data);
};

export const updateOrder = (
  data: UpdateOrderRequest
): Promise<MyServerReponse<StatusResponse>> => {
  return callServer(POST, "/admin/updateOrder", data);
};

export const updateShipDate = (
  data: UpdateShipDateRequest
): Promise<MyServerReponse<StatusResponse>> => {
  return callServer(POST, "/admin/updateShipDate", data);
};
