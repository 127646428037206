import React from "react";
import { AdminForm } from "./AdminForm/AdminForm";
import "./AdminPage.scss";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { routeName } from "config/routeConfig";

type Props = RouteComponentProps;

const AdminPageComponent: React.FC<Props> = (props) => {

  const goToLoginPage = () => {
    props.history.push({
      pathname: routeName.LoginPage,
    });
  };

  return (
    <div className="AdminPage">
      <AdminForm onUnAuthorize={goToLoginPage} />
    </div>
  );
};



const AdminPage = withRouter(AdminPageComponent);
AdminPage.displayName = "AdminPage";

export { AdminPage };
