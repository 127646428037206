import React from "react";
import { CommonPage } from "shared/Component/CommonPage/CommonPage";
import { TrackingResponseData } from "shared/utils/callServer/type";
import { TrackingForm } from "./TrackingForm/TrackingForm";
import { TrackingTable } from "./TrackingTable/TrackingTable";
import "./TrackingPage.scss";

const TrackingPage: React.FC<{}> = () => {
  const [errorMsg, setErrorMsg] = React.useState<string>("");
  const [trackingData, setTrackingData] = React.useState<
    TrackingResponseData[]
  >([]);
  const handleFormError = (error: string) => {
    setErrorMsg(error);
  };

  return (
    <CommonPage headerText="ติดตามสถานะการจัดส่ง" goToHomePage={true}>
      <>
        <TrackingForm
          onFormError={handleFormError}
          errorText={errorMsg}
          onFormSuccess={setTrackingData}
        />
        <TrackingTable trackingData={trackingData} />
      </>
    </CommonPage>
  );
};

TrackingPage.displayName = "TrackingPage";

export { TrackingPage };
