import { ExportDataField } from "./ExportDataTable";

const statusField = "mappedStatus";

export const exportDataRow: ExportDataField[] = [
  {
    text: "", // New line
    field: "",
  },
  {
    text: "จำนวนชิ้น",
    field: "numberPiece",
  },
  {
    text: "จำนวน Set",
    field: "numberSet",
  },
  {
    text: "ราคารวม",
    field: "basePrice",
  },
  {
    text: "ค่าส่ง",
    field: "shippingFee",
  },
  {
    text: "ส่วนลด (%)",
    field: "percentDiscount",
  },
  {
    text: "ราคารวมส่ง",
    field: "finalPrice",
  },
  {
    text: "เลขพัสดุ",
    field: "trackingNumber",
  },
  {
    text: "สรุปยอด",
    field: "",
  },
  {
    text: "สถานะการสั่ง",
    field: statusField,
  },
  {
    text: "กล่อง 4 ชิ้น",
    field: "bigBox",
  },
  {
    text: "กล่อง 2 ชิ้น",
    field: "smallBox",
  },
  {
    text: "ที่อยู่ผู้รับ",
    field: "address",
  },
  {
    text: "ชื่อผู้รับ",
    field: "name",
  },
  {
    text: "รายการที่สั่ง",
    field: "",
  },
  {
    text: "กล่องที่ใส่",
    field: "",
  },
  {
    text: "Special Request",
    field: "note",
  },
  {
    text: "วันส่ง",
    field: "shipDateString",
  },
];
