import React from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "./PageNumber.scss";

interface OwnProps {
  page: number;
  currentItem: number;
  totalItem: number;
  itemPerPage: number;
  updatePage: (page :number) => void;
}

type Props = OwnProps;
const PageNumber: React.FC<Props> = (props) => {
  const { page, currentItem, totalItem, updatePage, itemPerPage } = props;

  const totalPage = Math.ceil(totalItem /  itemPerPage)
  const disableBack = () => {
    return page <= 1
  }
  const disableForward = () => {
    return page >= totalPage
  }
  const onBackClick = () => {
    if(disableBack()) return;
    updatePage(page - 1)
  }

  const onForwardClick = () => {
    if(disableForward()) return;
    updatePage(page + 1)
  }

  const firstItem = (page - 1) * itemPerPage + 1
  return (
    <div className="PageNumber">
      <div className="PageNumber__Current">
        <ArrowBackIosIcon className="PageNumber__Button PageNumber__Button--back" onClick={onBackClick}/>
        <span className="PageNumber__Text">page {page}</span>
        <ArrowForwardIosIcon className="PageNumber__Button" onClick={onForwardClick}/>
      </div>
      <div> {firstItem} - {firstItem - 1 + currentItem} / total {totalItem}</div>
    </div>
  );
};

PageNumber.displayName = "PageNumber";
export { PageNumber };
