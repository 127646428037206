import React, { ReactElement } from "react";
import "./Footer.scss";
import { IconLink } from "../IconLink/IconLink";
import { generateClassName } from "shared/utils/reactClassName";

interface Props {
  spaceBetween?: boolean;
  fixed?: boolean;
  children?: ReactElement;
}


const Footer: React.FC<Props> = (props) => {
  const content = props.children || (
    <>
      <IconLink
        link="https://lin.ee/m8D6X9a"
        icon="https://img2.pic.in.th/pic/line-logo3d5554c0eb57ef16.png"
        text=""
        dark={false}
      />
      <IconLink
        link="https://www.facebook.com/ShangarilaMoonCake"
        icon="https://img2.pic.in.th/pic/fb-logo-1.png"
        text="ShangarilaMoonCake"
        dark={false}
      />
    </>
  )

  return (
    <div className={generateClassName("Footer", [props.spaceBetween ? "space" : "", props.fixed ? "fixed" : ""])}>
        {content}
    </div>
  );
};
Footer.displayName = "Footer";

export { Footer };
