import React from "react";
import { TrackingResponseData } from "shared/utils/callServer/type";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./TrackingTable.scss";

interface OwnProps {
  trackingData: TrackingResponseData[];
}

type Props = OwnProps;
const TrackingTable: React.FC<Props> = (props) => {
  const { trackingData } = props;

  if (trackingData.length <= 0) return null;
  const cellStyle = {
    fontSize: "1em",
  };

  return (
    <div className="TrackingTable__Container">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={cellStyle}>หมายเลขคำสั่งซื้อ</TableCell>
              <TableCell sx={cellStyle}>
                สถานะ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trackingData.map((data) => (
              <TableRow
                key={data.orderNumber}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={cellStyle} component="th" scope="row">
                  {data.orderNumber}
                </TableCell>
                <TableCell sx={cellStyle}>
                  {data.status}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="TrackingTable__Note">
        <div>สถานะการชำระเงิน update ภายใน 3 ชั่วโมงหลังชำระเงิน</div>
        <div>เมื่อได้เลขพัสดุแล้ว <a href="https://www.jtexpress.co.th/service/track">ติดตามสถานะการจัดส่งได้ที่นี่</a></div>
      </div>
    </div>
  );
};

TrackingTable.displayName = "TrackingTable";
export { TrackingTable };
