import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./OrderSummaryPage.scss";
import { OrderSummaryPageProps } from "./type";
import { FormButton } from "shared/Component/FormButton/FormButton";
import { ItemCard } from "shared/Component/ItemCard/ItemCard";
import { CakeType } from "constants/CakeType";
import { routeName } from "config/routeConfig";
import { getDiscountPrice, getMaxBoxNumber, getRandomSatang } from "./mapper";
import {
  getBoxNumber,
  setBoxNumberToStorage,
  setIsSubmitToStorage,
  setRandomSatangToStorage,
} from "shared/utils/localstorage";
import { goToHomePage } from "shared/utils/historyUtils";
import { OrderSummary } from "shared/Component/OrderSummary/OrderSummary";
import { CommonPage } from "shared/Component/CommonPage/CommonPage";
import { PriceSection } from "../shared/Component/PriceSection/PriceSection";
import { getPercentDiscount } from "shared/utils/callServer/callServer";
import { AddressPageProps } from "AddressPage/AddressPage";
import { GetPercentDiscountResponse } from "shared/utils/callServer/type";

type Props = RouteComponentProps;

const AMOUNT_IN_BOX = {
  small: 2,
  big: 4,
};

const OrderSummaryPageComponent: React.FC<Props> = (props) => {
  const [pageProps, setPageProps] = React.useState<OrderSummaryPageProps>();
  const [boxNumber, setBoxNumber] = React.useState(getBoxNumber());
  const [totalPieceInBox, setTotalPieceInBox] = React.useState(0);
  const [percentDiscount, setPercentDiscount] =
    React.useState<GetPercentDiscountResponse>({
      id: 0,
      discount: 0,
    });
  const [finalPrice, setFinalPrice] = React.useState(0);

  React.useEffect(() => {
    if (!props.location.state) {
      goToHomePage(props.history);
      return;
    }
    setPageProps(props.location.state as OrderSummaryPageProps);
    setRandomSatangToStorage(getRandomSatang());
    getPercentDiscount({}).then((result) => {
      if (result && result.data && result.data.id) {
        setPercentDiscount(result.data);
      }
    });
  }, []);

  React.useEffect(() => {
    if (!pageProps) {
      return;
    }
    setFinalPrice(
      getDiscountPrice(pageProps.price, percentDiscount.discount) +
      pageProps.shippingFee
    );
  }, [pageProps, percentDiscount.discount]);

  if (!pageProps) {
    return null;
  }

  const goToNextPage = () => {
    setBoxNumberToStorage(boxNumber);
    setIsSubmitToStorage(false);
    const nextPageProps: AddressPageProps = {
      ...pageProps,
      percentDiscount,
      finalPrice,
      smallBox: boxNumber[0],
      bigBox: boxNumber[1],
    };
    props.history.push({
      pathname: routeName.AddressPage,
      state: nextPageProps,
    });
  };

  const smallBoxProps = {
    id: -AMOUNT_IN_BOX.small,
    name: `กล่อง ${AMOUNT_IN_BOX.small} ลูก`,
    imageUrl: process.env.REACT_APP_SMALL_BOX_IMAGE_URL || '',
    price: 0,
    info: `แถมฟรีเมื่อซื้อขนมไหว้พระจันทร์ลูกใหญ่ ${AMOUNT_IN_BOX.small} ชิ้น`,
    type: CakeType.Piece,
  };

  const bigBoxProps = {
    id: -AMOUNT_IN_BOX.big,
    name: `กล่อง ${AMOUNT_IN_BOX.big} ลูก`,
    imageUrl: process.env.REACT_APP_BIG_BOX_IMAGE_URL || '',
    price: 0,
    info: `แถมฟรีเมื่อซื้อขนมไหว้พระจันทร์ลูกใหญ่ ${AMOUNT_IN_BOX.big} ชิ้น`,
    type: CakeType.Piece,
  };

  const onBoxChanged = (value: number, index: number) => {
    const newNumber = { ...boxNumber };
    newNumber[index] = value;
    setBoxNumber(newNumber);
    setTotalPieceInBox(
      newNumber[0] * AMOUNT_IN_BOX.small + newNumber[1] * AMOUNT_IN_BOX.big
    );
  };

  const selectedBoxPanel =
    pageProps.amount.piece > 1 ? (
      <>
        <div className="OrderSummaryPage__Item">
          เลือกกล่องที่ต้องการ (ฟรี) สำหรับ {pageProps.amount.piece} ลูก
        </div>
        <div className="OrderSummaryPage__CardContainer">
          <ItemCard
            {...smallBoxProps}
            onQuantityChange={(num: number) => onBoxChanged(num, 0)}
            value={boxNumber[0]}
            itemAvailable={100}
            maxValue={getMaxBoxNumber(
              pageProps.amount.piece,
              boxNumber[1] * AMOUNT_IN_BOX.big,
              AMOUNT_IN_BOX.small
            )}
            disablePlus={
              totalPieceInBox + AMOUNT_IN_BOX.small > pageProps.amount.piece
            }
          />
          <ItemCard
            {...bigBoxProps}
            onQuantityChange={(num: number) => onBoxChanged(num, 1)}
            value={boxNumber[1]}
            itemAvailable={100}
            maxValue={getMaxBoxNumber(
              pageProps.amount.piece,
              boxNumber[0] * AMOUNT_IN_BOX.small,
              AMOUNT_IN_BOX.big
            )}
            disablePlus={
              totalPieceInBox + AMOUNT_IN_BOX.big > pageProps.amount.piece
            }
          />
        </div>
      </>
    ) : null;

  return (
    <CommonPage headerText="สรุปรายการ">
      <>
        <OrderSummary
          mooncakeData={pageProps.mooncakeData}
          amount={pageProps.amount}
          shippingFee={pageProps.shippingFee}
        />

        <PriceSection
          crossOutprice={pageProps.price + pageProps.shippingFee}
          price={finalPrice}
          percentDiscount={percentDiscount.discount}
        />
        <div className="OrderSummaryPage__Item">
          <span >ส่งของภายใน 2-3 วัน</span>
          <span className="OrderSummaryPage__ItemSmall">(หากต้องการด่วนทักมาทาง Line/FB ด้านล่างค่ะ)</span>
        </div>

        {selectedBoxPanel}
        <FormButton text="สั่งซื้อ" onClick={goToNextPage} />
      </>
    </CommonPage>
  );
};

const OrderSummaryPage = withRouter(OrderSummaryPageComponent);
OrderSummaryPage.displayName = "OrderSummaryPage";

export { OrderSummaryPage };
