import React from "react";
import "./IconHover.scss";
import { InfoBox } from "CatalogPage/InfoBox/InfoBox";
import { IconText } from "../AdminOrderTable/IconText";

interface OwnProps {
  icon: IconText;
  onClick: () => void;
}

type Props = OwnProps;
const IconHover: React.FC<Props> = ({ icon, onClick }) => {
  const [hoverText, setHoverText] = React.useState<string>();
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout>();
  const onMouseOver = (name: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const timeId = setTimeout(() => {
      setHoverText(name);
    }, 600);
    setTimeoutId(timeId);
  };

  const onMouseOut = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setHoverText("")
  }

  return (
    <span className="IconHover">
      <span className="IconHover__Icon" onMouseOver={() => onMouseOver(icon.name)} onMouseOut={onMouseOut} onClick={onClick}>{icon.icon}</span>
      {hoverText && <InfoBox text={hoverText} />}
    </span>
  );
};

IconHover.displayName = "IconHover";
export { IconHover };
