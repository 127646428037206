import { OrderStatus } from "constants/OrderStatus";
import { DropDownItem } from "shared/Component/Dropdown/Dropdown";
import PaidIcon from '@mui/icons-material/Paid';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import UndoIcon from '@mui/icons-material/Undo';
import { AdminOrderData } from "./AdminOrderTable";
import { IconText } from "./IconText";

export const shipDateField = "shipDate";
export const trackingNumberField = "trackingNumber";
export const createdAtField = "createdAt";
export const statusDropdownData: DropDownItem[] = [
  {
    text: OrderStatus.notPaid,
    value: OrderStatus.notPaid,
  },
  {
    text: OrderStatus.toBeOrder,
    value: OrderStatus.toBeOrder,
  },
  {
    text: OrderStatus.toBeShip,
    value: OrderStatus.toBeShip,
  },
  {
    text: OrderStatus.shipped,
    value: OrderStatus.shipped,
  },
  {
    text: OrderStatus.stock,
    value: OrderStatus.stock,
  },
  {
    text: OrderStatus.deleted,
    value: OrderStatus.deleted,
  },
];

export const adminOrderColumns: AdminOrderData[] = [
  {
    title: "Order Number",
    field: "orderNumber",
    align: "left",
  },
  {
    title: "Name",
    field: "name",
    align: "left",
  },
  {
    title: "Date",
    field: "createdAt",
    align: "right",
  },
  {
    title: "Base Price",
    field: "basePrice",
    align: "right",
    isNumber: true,
  },
  // {
  //   title: "Shipping Fee",
  //   field: "shippingFee",
  //   align: "right",
  //   isNumber: true,
  // },
  {
    title: "Final Price",
    field: "finalPrice",
    align: "right",
    isNumber: true,
  },
  {
    title: "Status",
    field: "status",
    align: "left",
  },
  {
    title: "Ship Date",
    field: shipDateField,
    align: "left",
  },
  {
    title: "Tracking",
    field: trackingNumberField,
    align: "left",
  },
];

export const getActionCell = (status: OrderStatus): IconText[] => {
  switch (status) {
    case OrderStatus.deleted: {
      return (
        [
          { icon: <RestoreFromTrashIcon />, name: "Move to 'Not Paid'" }
        ]
      )
    }
    case OrderStatus.notPaid: {
      return (
        [
          { icon: <PaidIcon />, name: "Paid" },
          { icon: <DeleteIcon />, name: "Delete" }
        ]
      )
    }
    case OrderStatus.toBeOrder: {
      return (
        [
          { icon: <ShoppingCartIcon />, name: "Finish Exporting" },
          { icon: <InventoryIcon />, name: "Move to stock" },
          { icon: <UndoIcon />, name: "Not Paid" },
        ]
      )
    }
    case OrderStatus.toBeShip: {
      return (
        [
          { icon: <LocalShippingIcon />, name: "Sent" },
          { icon: <UndoIcon />, name: "To Be Order" },
        ]
      )
    }
    case OrderStatus.stock:
      return (
        [
          { icon: <UndoIcon />, name: "To Be Order" },
        ]
      )
    case OrderStatus.shipped: {
      return (
        [
          { icon: <UndoIcon />, name: "Not Ship" },
        ]
      )
    }
  }
  return [];
}