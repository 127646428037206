// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderSummary__Amount {
  padding: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/shared/Component/OrderSummary/OrderSummary.scss"],"names":[],"mappings":"AAGE;EACE,eAAA;AAFJ","sourcesContent":["@import \"../../common.scss\";\n\n.OrderSummary {\n  &__Amount {\n    padding: 10px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
