// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TotalAmount--color .TotalAmount__Title {
  color: #70120d;
}
.TotalAmount__Title {
  padding-right: 6px;
}`, "",{"version":3,"sources":["webpack://./src/shared/Component/TotalAmount/TotalAmount.scss"],"names":[],"mappings":"AAII;EACE,cAAA;AAHN;AAOE;EACE,kBAAA;AALJ","sourcesContent":["@import \"../../common.scss\";\n\n.TotalAmount {\n  &--color {\n    .TotalAmount__Title {\n      color: $secondaryColor;\n    }\n  }\n\n  &__Title {\n    padding-right: 6px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
