import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AddressPageProps } from "AddressPage/AddressPage";
import { TextInfo } from "shared/Component/TextInfo/TextInfo";
import { FormButton } from "shared/Component/FormButton/FormButton";
import { routeName } from "config/routeConfig";
import { goToHomePage } from "shared/utils/historyUtils";
import { Toast } from "shared/Component/Toast/Toast";
import { paymentComplete } from "shared/utils/callServer/callServer";
import { AddressFormValues } from "AddressPage/AddressForm/types";
import { OrderSummary } from "shared/Component/OrderSummary/OrderSummary";
import { CollapseBox } from "shared/Component/CollapseBox/CollapseBox";
import { AddressSummary } from "shared/Component/AddressSummary/AddressSummary";
import { ErrorMessage } from "shared/utils/message";
import { CommonPage } from "shared/Component/CommonPage/CommonPage";
import "./PaymentPage.scss";
import { isMockMode } from "shared/utils/configurationsHelper";
import { PriceSection } from "shared/Component/PriceSection/PriceSection";
import { getQR } from "./mapper";

type Props = RouteComponentProps;

export interface PaymentPageProps extends AddressPageProps {
  orderNumber: string;
  qrCode: string;
  extraCost: number;
  address: AddressFormValues;
}

const PaymentPageComponent: React.FC<Props> = (props) => {
  const [pageProps, setPageProps] = React.useState<PaymentPageProps>();
  const [toastMessage, setToastMessage] = React.useState<string>();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(true);
  const [svgCode, setSvgCode] = React.useState<string>("");

  React.useEffect(() => {
    const pagePropsToSet = props.location.state as PaymentPageProps

    if (!pagePropsToSet) {
      goToHomePage(props.history);
      return
    }

    setPageProps(pagePropsToSet);
    getQR(pagePropsToSet.qrCode).then(
      (result) => {
        setSvgCode(result)
      },
      (error) => {
        console.log("Error while getting qr code ", error)
        setToastMessage(ErrorMessage.cannotGetData);
      }
    );

    setTimeout(() => {
      // Delay to prevent the double click from previous page.
      setIsSubmitting(false);
    }, 1000);
  }, [props.location, props.history]);


  if (!pageProps) {
    return null;
  }

  const goToNextPage = (orderNumber: string) => {
    props.history.push({
      pathname: routeName.ThankYouPage,
      state: {
        ...pageProps,
        orderNumber,
      },
    });
  };

  const onButtonClick = () => {
    setIsSubmitting(true);
    if (isMockMode()) {
      setIsSubmitting(false);
      goToNextPage("MockNumber");
      return;
    }
    // send a request to a server
    paymentComplete({
      orderNumber: pageProps.orderNumber,
    }).then(
      (result) => {
        setIsSubmitting(false);
        if (!result || !result.data) {
          setToastMessage(ErrorMessage.cannotGetData);
          return;
        }
        if (!result.data.orderNumber) {
          setToastMessage(
            result.data.errorMessage || ErrorMessage.cannotGetData
          );
          return;
        }
        goToNextPage(result.data.orderNumber);
      },
      (error) => {
        setIsSubmitting(false);
        setToastMessage(error && error.message);
      }
    );
  };


  return (
    <CommonPage headerText="ชำระเงิน">
      {/* <CopyButton text={pageProps.finalPrice.toString()} /> */}
      <>
        <div className="PaymentPage__Title">
          แสกน QR Code เพื่อโอนเงิน
        </div>
        <div className="PaymentPage__Image" dangerouslySetInnerHTML={{ __html: svgCode }} />
        <PriceSection
          crossOutprice={pageProps.price + pageProps.shippingFee + pageProps.extraCost}
          price={pageProps.finalPrice + pageProps.extraCost}
          percentDiscount={pageProps.percentDiscount.discount}
        />
        {pageProps.extraCost > 0 && <TextInfo title="หมายเหตุ*" text={`พื้นที่จัดส่ง ${pageProps.address.zipCode} เป็นพื้นที่ห่างไกล คิดค่าส่งเพิ่ม ${pageProps.extraCost} บาท`} />}
        <TextInfo title="ชื่อร้าน" text="SCB มณี Shop (ขนมไหว้พระจันทร์ เชียงการีล่า)" />
        <TextInfo title="ธนาคาร" text="ไทยพาณิชย์ (SCB)" />
        <TextInfo title="ชื่อบัญชี" text="จินตนา อุ่นพานทอง" />
        <TextInfo title="เลขบัญชี" text="170-411318-5" copyText={"1704113185"} />
        <TextInfo title="หมายเลขสั่งซื้อ" text={pageProps.orderNumber} />
        <CollapseBox title="ดูรายการสั่งซื้อ">
          <OrderSummary
            mooncakeData={pageProps.mooncakeData}
            amount={pageProps.amount}
            shippingFee={pageProps.shippingFee + pageProps.extraCost}
          />
        </CollapseBox>
        <CollapseBox title="ตรวจสอบที่อยู่">
          <AddressSummary address={pageProps.address} />
        </CollapseBox>
        <FormButton text="โอนเงินเรียบร้อย" onClick={onButtonClick} disabled={isSubmitting} />
        {toastMessage && (
          <Toast text={toastMessage} clearTextFunction={setToastMessage} />
        )}
      </>
    </CommonPage>
  );
};

const PaymentPage = withRouter(PaymentPageComponent);
PaymentPage.displayName = "PaymentPage";

export { PaymentPage };
