import React from "react";
import { Form, FormikProps, withFormik } from "formik";
import { initTrackingFormValue, validateTrackingSchema } from "./validation";
import { withRouter, RouteComponentProps } from "react-router";
import { track } from "shared/utils/callServer/callServer";
import { ErrorMessage } from "shared/utils/message";
import { TrackingFormValues } from "./types";
import { FormInput } from "shared/Component/FormInput/FormInput";
import { FormButton } from "shared/Component/FormButton/FormButton";
import { TrackingResponseData } from "shared/utils/callServer/type";
import "./TrackingForm.scss";

interface OwnProps {
  errorText?: string;
  onFormError: (error: string) => void;
  onFormSuccess: (data: TrackingResponseData[]) => void;
}

type Props = OwnProps & RouteComponentProps & FormikProps<TrackingFormValues>;
const TrackingFormComponent: React.FC<Props> = (props) => {
  const { isSubmitting, errorText } = props;

  return (
    <Form className="TrackingForm">
      <FormInput
        label="หมายเลขคำสั่งซื้อ"
        name="orderNumber"
        type="text"
        error={!!props.errors.orderNumber}
      />
      <div className="TrackingForm__Text">หรือ</div>
      <FormInput
        label="อีเมล"
        name="email"
        type="email"
        error={!!props.errors.email}
      />

      <FormButton text="ค้นหา" disabled={isSubmitting} errorText={errorText} />
    </Form>
  );
};

const TrackingForm = withRouter(
  withFormik<OwnProps & RouteComponentProps, TrackingFormValues>({
    mapPropsToValues: initTrackingFormValue,
    validationSchema: validateTrackingSchema,
    validate: (values: TrackingFormValues) => {
      if (!values.orderNumber && !values.email) {
        return {
          orderNumber: "กรุณาใส่หมายเลขสั่งซื้อหรืออีเมล",
          email: "กรุณาใส่หมายเลขสั่งซื้อหรืออีเมล",
        };
      }

      return {};
    },
    handleSubmit: (values: TrackingFormValues, { props, setSubmitting }) => {
      // send a request to a server
      track(values).then(
        (result) => {
          setSubmitting(false);
          if (!result || !result.data) {
            props.onFormSuccess([]);
            props.onFormError(ErrorMessage.cannotGetData);
            return;
          }
          if (result.data.error) {
            props.onFormSuccess([]);
            props.onFormError(result.data.error);
            return;
          }
          props.onFormError("");
          props.onFormSuccess(result.data.data);
          return;
        },
        (error) => {
          setSubmitting(false);
          props.onFormSuccess([]);
          props.onFormError(error && error.message);
        }
      );
    },
    displayName: "TrackingForm",
  })(TrackingFormComponent)
);

TrackingForm.displayName = "TrackingForm";
export { TrackingForm };
