// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminPage {
  background-color: rgba(255, 255, 255, 0.8705882353);
  border-radius: 8px;
  padding: 40px 3%;
  margin: 40px auto;
  width: 90%;
}`, "",{"version":3,"sources":["webpack://./src/AdminPage/AdminPage.scss"],"names":[],"mappings":"AAEA;EACI,mDAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,UAAA;AADJ","sourcesContent":["@import \"../shared/common.scss\";\n\n.AdminPage {\n    background-color: #ffffffde;\n    border-radius: 8px;\n    padding: 40px 3%;\n    margin: 40px auto;\n    width: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
