import { AddressPage } from "AddressPage/AddressPage";
import { OrderSummaryPage } from "OrderSummaryPage/OrderSummaryPage";
import { PaymentPage } from "PaymentPage/PaymentPage";
import { ComponentClass } from "react";
import { ThankYouPage } from "ThankYouPage/ThankYouPage";
import { CatalogPage } from "CatalogPage/CatalogPage";
import { TrackingPage } from "TrackingPage/TrackingPage";
import { AdminPage } from "AdminPage/AdminPage";
import { LoginPage } from "LoginPage/LoginPage";

export interface RouteObject {
  url: string;
  component: ComponentClass | React.FC;
}

export const routeName: { [id: string]: string } = {
  CatalogPage: "/",
  OrderSummaryPage: "/order-summary",
  AddressPage: "/address",
  PaymentPage: "/payment",
  ThankYouPage: "/thankyou",
  TrackingPage: "/tracking",
  LoginPage: "/green",
  AdminPage: "/nimda",
};

export const routeConstants: RouteObject[] = [
  { url: routeName.CatalogPage, component: CatalogPage },
  { url: routeName.OrderSummaryPage, component: OrderSummaryPage },
  { url: routeName.AddressPage, component: AddressPage },
  { url: routeName.PaymentPage, component: PaymentPage },
  { url: routeName.ThankYouPage, component: ThankYouPage },
  { url: routeName.TrackingPage, component: TrackingPage },
  { url: routeName.LoginPage, component: LoginPage },
  { url: routeName.AdminPage, component: AdminPage },
];
