// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TrackingTable__Container {
  padding: 20px 0;
}
.TrackingTable__Note {
  text-align: center;
  font-size: 0.8em;
  padding: 20px 0;
  line-height: 2em;
}`, "",{"version":3,"sources":["webpack://./src/TrackingPage/TrackingTable/TrackingTable.scss"],"names":[],"mappings":"AAGE;EACE,eAAA;AAFJ;AAKE;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AAHJ","sourcesContent":["@import \"../../shared/common.scss\";\n\n.TrackingTable {\n  &__Container {\n    padding: 20px 0;\n  }\n\n  &__Note {\n    text-align: center;\n    font-size: 0.8em;\n    padding: 20px 0;\n    line-height: 2em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
