import React, { ChangeEvent } from "react";
import { AdminOrderStatus } from "shared/utils/callServer/type";
import "./AdminOrderTable.scss";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { adminOrderColumns, createdAtField, getActionCell, shipDateField, trackingNumberField } from "./mapper";
import { OrderStatus } from "constants/OrderStatus";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { InfoBox } from "CatalogPage/InfoBox/InfoBox";
import { IconHover } from "../IconHover/IconHover";
interface OwnProps {
  data: AdminOrderStatus[];
  onActionClick: (o: string, i: number, track?: string) => void;
  onShipDateChange: (o: string, d: Date) => void
}

export interface AdminOrderData {
  title: string;
  field: string;
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  isNumber?: boolean;
}
type Props = OwnProps;


const AdminOrderTable: React.FC<Props> = (props) => {

  const [trackingNumber, setTrackingNumber] = React.useState<string>();

  const onTrackingNumberChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTrackingNumber(e.target.value)
    },
    []
  );

  const { data, onActionClick, onShipDateChange } = props;
  if (data.length <= 0) return null;

  const headerStyle = {
    fontSize: "1em",
    color: "#e6e6e6"
  };

  const cellStyle = {
    fontSize: "1em",
    padding: "4px 8px",
  };

  const getCellContent = (data: AdminOrderStatus, item: AdminOrderData): JSX.Element => {
    if (data.status === OrderStatus.toBeShip && item.field === trackingNumberField) {
      return <TextField id="orderNumber" variant="standard" defaultValue={data[item.field]} onChange={onTrackingNumberChange} />
    } else if (data.status === OrderStatus.toBeOrder && item.field === shipDateField) {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            slotProps={{ textField: { variant: 'standard', } }}
            defaultValue={dayjs(data.shipDate)}
            format="MMM DD"
            onChange={(newValue) => newValue && onShipDateChange(data.orderNumber, newValue.toDate())}
            views={['month', 'day']} />
        </LocalizationProvider>
      )
    } else if (item.field === shipDateField && data.shipDate) {
      return <>{dayjs(data.shipDate).format('MMM DD')}</>
    } else if (item.field === createdAtField) {
      return <>{dayjs(data.createdAt).format('MMM DD, HH:mm')}</>
    }
    return item.isNumber ? Intl.NumberFormat("th-th", { minimumFractionDigits: 2 }).format(data[item.field]) : data[item.field] || "-"
  }

  const header = adminOrderColumns.map(item => {
    return (
      <TableCell className="AdminOrderTable__HeaderCell" key={item.title} align={item.align} sx={headerStyle}>
        {item.title}
      </TableCell>
    )
  })

  return (
    <TableContainer component={Paper} className="AdminOrderTable__Container">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className="AdminOrderTable__Header">
            {header}
            <TableCell className="AdminOrderTable__HeaderCell" sx={headerStyle}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((data) => (
            <TableRow
              key={data.orderNumber}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              className="AdminOrderTable__Item"
            >
              {adminOrderColumns.map(item => {
                return (
                  <TableCell sx={cellStyle} align={item.align} key={item.field}>
                    {
                      getCellContent(data, item)
                    }
                  </TableCell>
                )
              })}
              <TableCell sx={cellStyle}>
                {getActionCell(data.status).map((icon, i) => {
                  return (
                    <IconHover key={i} icon={icon} onClick={() => onActionClick(data.orderNumber, i, trackingNumber)} />
                  )
                })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  );
};

AdminOrderTable.displayName = "AdminOrderTable";
export { AdminOrderTable };
