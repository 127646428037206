import * as yup from "yup";
import {
  validateTextNotRequired,
  validateEmailNotRequired,
} from "shared/utils/yupValidation";
import { getOrderNumber, getEmail } from "shared/utils/localstorage";

export const initTrackingFormValue = () => {
  return {
    orderNumber: getOrderNumber(),
    email: getEmail(),
  };
};

export const validateTrackingSchema = () => {
  return yup.object().shape({
    orderNumber: validateTextNotRequired(
      6,
      "หมายเลขคำสั่งซื้อประกอบด้วยตัวอักษรอย่างน้อย 6 ตัว"
    ),
    email: validateEmailNotRequired(),
  });
};
