import React from "react";
import { Form, FormikProps, withFormik } from "formik";
import { initLoginFormValue, validateLoginSchema } from "./validation";
import { withRouter, RouteComponentProps } from "react-router";
import { login } from "shared/utils/callServer/callServer";
import { ErrorMessage } from "shared/utils/message";
import { LoginFormValues } from "./types";
import { FormInput } from "shared/Component/FormInput/FormInput";
import { FormButton } from "shared/Component/FormButton/FormButton";
import "./LoginForm.scss";
import { routeName } from "config/routeConfig";
import { setTokenToStorage } from "shared/utils/localstorage";

interface OwnProps {
  errorText?: string;
  onFormError: (error: string) => void;
}

type Props = OwnProps & RouteComponentProps & FormikProps<LoginFormValues>;
const LoginFormComponent: React.FC<Props> = (props) => {
  const { isSubmitting, errorText } = props;

  return (
    <Form className="LoginForm">
      <FormInput
        label="Username"
        name="username"
        type="text"
        error={!!props.errors.username}
      />
      <FormInput
        label="Password"
        name="password"
        type="password"
        error={!!props.errors.password}
      />

      <FormButton text="Login" disabled={isSubmitting} errorText={errorText} />
    </Form>
  );
};

const LoginForm = withRouter(
  withFormik<OwnProps & RouteComponentProps, LoginFormValues>({
    mapPropsToValues: initLoginFormValue,
    validationSchema: validateLoginSchema,
    handleSubmit: (values: LoginFormValues, { props, setSubmitting }) => {
      // send a request to a server
      login(values).then(
        (result) => {
          setSubmitting(false);

          if (!result || !result.data || !result.data.token) {
            props.onFormError(ErrorMessage.cannotGetData);
            return;
          }
          setTokenToStorage(result.data.token);
          props.onFormError("");
          props.history.push({
            pathname: routeName.AdminPage,
          });
          return;
        },
        (error) => {
          props.onFormError(error && error.message);
          setSubmitting(false);

        }
      );
    },
    displayName: "LoginForm",
  })(LoginFormComponent)
);

LoginForm.displayName = "LoginForm";
export { LoginForm };
