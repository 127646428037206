import qrcode from "qrcode";

export const getQR = (qrCode: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const options = { type: 'svg', color: { dark: '#003b6a', light: '#ffffffde' } }
    qrcode.toString(qrCode, options, (err, svg) => {
      if (err) return reject(err)
      resolve(svg)
    })
  })
};
