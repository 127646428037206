import React from "react";

import "./ExportDataTable.scss";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ExportData, ExportDataResponse } from "shared/utils/callServer/type";
import { exportDataRow } from "./mapper";

interface OwnProps {
  resp: ExportDataResponse;
}

export interface ExportDataField {
  text: string;
  field: string;
}
type Props = OwnProps;

const ExportDataTable: React.FC<Props> = (props) => {
  const { data, mooncakeName } = props.resp;
  if (data.length <= 0) return null;

  const headerStyle = {
    fontSize: "1em",
    color: "#e6e6e6",
  };

  const cellStyle = {
    fontSize: "1em",
    padding: "4px 8px",
  };

  const cellStyleMinor = {
    fontSize: "0.1em",
    padding: "4px 8px",
    color: "#eeeeee",
  };

  const getCellContent = (
    data: ExportData,
    item: ExportDataField
  ): JSX.Element => {
    return data[item.field] || "-";
  };

  const header = data.map((item) => {
    return (
      <TableCell
        className="ExportDataTable__HeaderCell"
        key={item.orderNumber}
        sx={headerStyle}
      >
        {item.orderNumber}
      </TableCell>
    );
  });

  return (
    <TableContainer component={Paper} className="ExportDataTable__Container">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className="ExportDataTable__Header">
            <TableCell
              className="ExportDataTable__HeaderCell ExportDataTable__Cell--fixed"
              sx={headerStyle}
            >
              Name
            </TableCell>
            {/* Table Head - Order Number */}

            {header}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Mooncake amount */}
          {mooncakeName.map((name, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              className="ExportDataTable__Item"
            >
              <TableCell
                className="ExportDataTable__Cell--fixed"
                sx={cellStyle}
              >
                {name}
              </TableCell>
              {data.map((data, dataIndex) => (
                <TableCell key={dataIndex} sx={data.mooncakeAmount[index] === 0 ? cellStyleMinor : cellStyle}>
                  {data.mooncakeAmount[index]}
                </TableCell>
              ))}
            </TableRow>
          ))}

          {/* other fields */}
          {exportDataRow.map((row) => (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              className="ExportDataTable__Item"
              key={row.text}
            >
              <TableCell
                className="ExportDataTable__Cell--fixed"
                sx={cellStyle}
              >
                {row.text}
              </TableCell>
              {data.map((data, index) => (
                <TableCell key={index} className="ExportDataTable__Cell" sx={cellStyle}>{getCellContent(data, row)}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ExportDataTable.displayName = "ExportDataTable";
export { ExportDataTable };
