// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TrackingForm {
  display: flex;
  flex-direction: column;
}
.TrackingForm__Text {
  text-align: center;
  font-style: italic;
  font-weight: bold;
  margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/TrackingPage/TrackingForm/TrackingForm.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AADJ","sourcesContent":["@import \"../../shared/common.scss\";\n\n.TrackingForm {\n  display: flex;\n  flex-direction: column;\n\n  &__Text {\n    text-align: center;\n    font-style: italic;\n    font-weight: bold;\n    margin-top: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
