// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderSummaryPage__Item {
  padding: 10px 0;
}
.OrderSummaryPage__ItemSmall {
  font-size: 0.8em;
  padding-left: 4px;
}
.OrderSummaryPage__CardContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/OrderSummaryPage/OrderSummaryPage.scss"],"names":[],"mappings":"AAGE;EACE,eAAA;AAFJ;AAKE;EACE,gBAAA;EACA,iBAAA;AAHJ;AAkBE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;AAhBJ","sourcesContent":["@import \"../shared/common.scss\";\n\n.OrderSummaryPage {\n  &__Item {\n    padding: 10px 0;\n  }\n\n  &__ItemSmall {\n    font-size: 0.8em;\n    padding-left: 4px;\n  }\n\n  // &__ItemHighlight {\n  //   font-weight: bold;\n  //   font-style: italic;\n  //   color: $darkTextColor2;\n  //   padding-right: 8px;\n  //   font-size: 1.1em;\n\n  //   &--big {\n  //     font-size: 1.2em;\n  //   }\n  // }\n\n  &__CardContainer {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    padding-bottom: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
