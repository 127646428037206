// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BgCard {
  background-color: rgba(255, 255, 255, 0.8705882353);
  border-radius: 8px;
  padding: 16px 20px;
  width: 80%;
}
@media (min-width: 1370px) {
  .BgCard {
    width: 60%;
    padding: 16px 32px;
  }
}
@media (max-width: 444px) {
  .BgCard {
    width: 97%;
    padding: 16px 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/Component/BgCard/BgCard.scss","webpack://./src/shared/common.scss"],"names":[],"mappings":"AAEA;EACE,mDAAA;EACA,kBAAA;EACA,kBAAA;EACA,UAAA;AADF;ACgDE;EDnDF;IAOI,UAAA;IACA,kBAAA;EAAF;AACF;AC2BE;EDpCF;IAYI,UAAA;IACA,kBAAA;EACF;AACF","sourcesContent":["@import \"../../common.scss\";\n\n.BgCard {\n  background-color: #ffffffde;\n  border-radius: 8px;\n  padding: 16px 20px;\n  width: 80%;\n\n  @include for-desktop-up {\n    width: 60%;\n    padding: 16px 32px;\n  }\n\n  @include for-phone-only {\n    width: 97%;\n    padding: 16px 12px;\n  }\n}","// color\n$mainColor: #d60000;\n$mainColorHover: #f54848;\n$mainColorFocus: #aa0000;\n\n$secondaryColor: #70120d;\n$secondaryColorAlpha: #ff9892;\n$secondaryColorLight: #ffd0cd;\n$bgColor: rgb(230, 230, 230);\n\n$lightText: #757575;\n$lightBackground: #f1f1f1;\n\n$quantityButton: #cdcdcd;\n$quantityButtonDisable: #e9e9e9;\n\n$hoverBackground: rgb(39, 39, 39);\n$hoverText: #e9e9e9;\n\n$mainTextColor: #e6e6e6;\n$greyTextColor: #737373;\n$darkTextColor: rgb(22, 22, 22);\n$darkTextColor2: rgb(71, 0, 0);\n\n$priceColor: rgb(253, 73, 63);\n\n$errorMessage: rgb(230, 38, 28);\n\n// Size\n$mobileFontSize: 15px;\n$headerPadding: 70px;\n$baseFontSize: 20px;\n\n// stlying\n$boxShadow: 1px 1px 5px #3d3d3d;\n\n// breakpoint\n@mixin for-phone-only {\n  @media (max-width: 444px) {\n    @content;\n  }\n}\n// @mixin for-tablet-portrait-up {\n//   @media (min-width: 600px) {\n//     @content;\n//   }\n// }\n@mixin for-tablet-landscape-up {\n  @media (min-width: 980px) {\n    @content;\n  }\n}\n@mixin for-desktop-up {\n  @media (min-width: 1370px) {\n    @content;\n  }\n}\n// @mixin for-big-desktop-up {\n//   @media (min-width: 1800px) {\n//     @content;\n//   }\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
