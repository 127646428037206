// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentPage__Title {
  color: #70120d;
  font-weight: bold;
  padding-top: 6px;
  font-size: 1.2em;
}
.PaymentPage__HighlightText {
  color: #70120d;
  padding-right: 8px;
}
.PaymentPage__Image {
  width: 60%;
  margin: auto;
}
@media (min-width: 1370px) {
  .PaymentPage__Image {
    width: 50%;
  }
}
@media (max-width: 444px) {
  .PaymentPage__Image {
    width: 80%;
  }
}`, "",{"version":3,"sources":["webpack://./src/PaymentPage/PaymentPage.scss","webpack://./src/shared/common.scss"],"names":[],"mappings":"AAGE;EACE,cCCa;EDAb,iBAAA;EACA,gBAAA;EACA,gBAAA;AAFJ;AAKE;EACE,cCNa;EDOb,kBAAA;AAHJ;AAME;EACE,UAAA;EACA,YAAA;AAJJ;ACwCE;EDtCA;IAKI,UAAA;EAHJ;AACF;ACoBE;EDvBA;IASI,UAAA;EAFJ;AACF","sourcesContent":["@import \"../shared/common.scss\";\n\n.PaymentPage {\n  &__Title {\n    color: $secondaryColor;\n    font-weight: bold;\n    padding-top: 6px;\n    font-size: 1.2em;\n  }\n\n  &__HighlightText {\n    color: $secondaryColor;\n    padding-right: 8px;\n  }\n\n  &__Image {\n    width: 60%;\n    margin: auto;\n\n    @include for-desktop-up {\n      width: 50%;\n    }\n\n    @include for-phone-only {\n      width: 80%;\n    }\n  }\n}\n","// color\n$mainColor: #d60000;\n$mainColorHover: #f54848;\n$mainColorFocus: #aa0000;\n\n$secondaryColor: #70120d;\n$secondaryColorAlpha: #ff9892;\n$secondaryColorLight: #ffd0cd;\n$bgColor: rgb(230, 230, 230);\n\n$lightText: #757575;\n$lightBackground: #f1f1f1;\n\n$quantityButton: #cdcdcd;\n$quantityButtonDisable: #e9e9e9;\n\n$hoverBackground: rgb(39, 39, 39);\n$hoverText: #e9e9e9;\n\n$mainTextColor: #e6e6e6;\n$greyTextColor: #737373;\n$darkTextColor: rgb(22, 22, 22);\n$darkTextColor2: rgb(71, 0, 0);\n\n$priceColor: rgb(253, 73, 63);\n\n$errorMessage: rgb(230, 38, 28);\n\n// Size\n$mobileFontSize: 15px;\n$headerPadding: 70px;\n$baseFontSize: 20px;\n\n// stlying\n$boxShadow: 1px 1px 5px #3d3d3d;\n\n// breakpoint\n@mixin for-phone-only {\n  @media (max-width: 444px) {\n    @content;\n  }\n}\n// @mixin for-tablet-portrait-up {\n//   @media (min-width: 600px) {\n//     @content;\n//   }\n// }\n@mixin for-tablet-landscape-up {\n  @media (min-width: 980px) {\n    @content;\n  }\n}\n@mixin for-desktop-up {\n  @media (min-width: 1370px) {\n    @content;\n  }\n}\n// @mixin for-big-desktop-up {\n//   @media (min-width: 1800px) {\n//     @content;\n//   }\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
