import React, { ChangeEvent } from "react";
import {
  exportData,
  getOrder,
  updateOrder,
  updateShipDate,
} from "shared/utils/callServer/callServer";
import { ErrorMessage } from "shared/utils/message";
import { Dropdown } from "shared/Component/Dropdown/Dropdown";
import {
  AdminOrderStatus,
  ExportDataResponse,
} from "shared/utils/callServer/type";
import { OrderStatus } from "constants/OrderStatus";
import { AdminOrderTable } from "./AdminOrderTable/AdminOrderTable";
import "./AdminForm.scss";
import { statusDropdownData } from "./AdminOrderTable/mapper";
import { Button, TextField } from "@mui/material";
import { ExportDataTable } from "./ExportDataTable/ExportDataTable";
import { LoadingAnimation } from "shared/Component/FormButton/LoadingAnimation/LoadingAnimation";
import { Overlay } from "shared/Component/Overlay/Overlay";
import { PageNumber } from "./PageNumber/PageNumber";
import { useDebouncedEffect } from "shared/customHook/useDebouncedEffect";
import { IconHover } from "./IconHover/IconHover";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

interface OwnProps {
  onUnAuthorize: () => void;
}

type Props = OwnProps;
const AdminForm: React.FC<Props> = (props) => {
  const [response, setResponse] = React.useState<AdminOrderStatus[]>([]);
  const [exportResponse, setExportResponse] =
    React.useState<ExportDataResponse>();
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [errorText, setErrorText] = React.useState<string>();
  const [filterName, setFilterName] = React.useState<string>();
  const [filterPrice, setFilterPrice] = React.useState<number>();
  const [page, setPage] = React.useState<number>(1);
  const [itemCount, setItemCount] = React.useState<number>(0);
  const [itemPerPage, setItemPerPage] = React.useState<number>(0);
  const [orderStatus, setOrderStatus] = React.useState<OrderStatus>(
    OrderStatus.all
  );

  const callGetOrder = () => {
    setIsLoading(true);
    getOrder({
      name: filterName,
      price: filterPrice,
      status: orderStatus,
      page: page
    }).then(
      (result) => {
        setIsLoading(false);
        if (!result || !result.data) {
          setErrorText(ErrorMessage.cannotGetData);
          return;
        }
        if (result.data.order.length < 1) {
          setErrorText(`ไม่มี order ตาม criteria `);
        }
        setResponse(result.data.order);
        setItemCount(result.data.allItemCount);
        setItemPerPage(result.data.itemPerPage);
      },
      (error) => {
        setIsLoading(false);
        if (error.message.indexOf(401) > 0) {
          props.onUnAuthorize();
        } else {
          setErrorText(error.message);
        }
      }
    );
  };

  const exitExportMode = () => {
    setExportResponse(undefined);
  }

  const toBeShipClicked = () => {
    if (!exportResponse) return;

    const callUpdateOrders = exportResponse.data.map(item => {
      return updateOrder({
        orderNumber: item.orderNumber,
        action: 0,
      });
    });

    setIsLoading(true);
    Promise.all(callUpdateOrders).then(
      () => {
        setIsLoading(false);
        setOrderStatus(OrderStatus.all)
        exitExportMode();
      },
      (error) => {
        setErrorText(error.message);
        setIsLoading(false);
      }
    );

  }

  const callExportData = () => {
    setIsLoading(true);
    exportData({
      name: filterName,
      price: filterPrice,
      status: orderStatus,
      page: page
    }).then(
      (result) => {
        setIsLoading(false);
        if (!result || !result.data) {
          setErrorText(ErrorMessage.cannotGetData);
          return;
        }
        if (result.data.data.length < 1) {
          setErrorText(`ไม่มี order ตาม criteria `);
        }
        setExportResponse(result.data);
      },
      (error) => {
        setIsLoading(false);
        if (error.message.indexOf(401) > 0) {
          props.onUnAuthorize();
        } else {
          setErrorText(error.message);
        }
      }
    );
  };

  const callUpdateShipdate = (orderNumber: string, date: Date) => {
    updateShipDate({
      orderNumber,
      date,
    }).then(
      (result) => {
        if (result.data.errorMessage) {
          setErrorText(result.data.errorMessage);
        }
      },
      (error) => {
        setErrorText(error.message);
      }
    );
  };

  React.useEffect(() => {
    if (orderStatus == OrderStatus.toBeOrder) {
      callExportData();
    } else {
      setExportResponse(undefined);
      callGetOrder();
    }
  }, [orderStatus, page]);

  const onActionClick = (
    orderNumber: string,
    index: number,
    trackingNumber?: string
  ) => {
    setIsLoading(true);
    updateOrder({
      orderNumber: orderNumber,
      action: index,
      trackingNumber,
    }).then(
      (result) => {
        if (result.data.errorMessage) {
          setIsLoading(false);
          setErrorText(result.data.errorMessage);
        } else {
          callGetOrder();
        }
      },
      (error) => {
        setIsLoading(false);
        setErrorText(error.message);
      }
    );
  };

  const onNameChange = React.useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setExportResponse(undefined);
    setFilterName(e.target.value)
  }, []);

  useDebouncedEffect(callGetOrder, [filterName, filterPrice], 500);

  const onPriceChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPage(1);
      setExportResponse(undefined);
      const value = Number(e.target.value);
      if (isNaN(value) || value === 0) {
        setFilterPrice(undefined)
      } else {
        setFilterPrice(value);
      }
    },
    []
  );

  const onOrderStatusSelect = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPage(1);
      setOrderStatus(e.target.value as OrderStatus)
    },
    []
  );

  const dropdownData = [
    {
      text: "All",
      value: OrderStatus.all,
    },
    ...statusDropdownData,
  ];

  const responseTable = (exportResponse && (
    <ExportDataTable resp={exportResponse} />
  )) ||
    (response && response.length > 0 && (
      <AdminOrderTable
        data={response}
        onActionClick={onActionClick}
        onShipDateChange={callUpdateShipdate}
      />
    )) || <div className="AdminForm__ErrorText">{errorText}</div>;

  const updatePage = (page: number) => {
    setPage(page);
  }
  const pageComponent = (response && response.length > 0 && (
    <PageNumber
      page={page}
      currentItem={response.length}
      totalItem={itemCount}
      itemPerPage={itemPerPage}
      updatePage={updatePage}
    />
  ))

  const buttons = exportResponse ? (
    <>
      {/* The copy function doesn't work on http */}
      {/* <Button
        variant="outlined"
        className="AdminForm__SecondaryButton"
        onClick={copyText}
      >
        Copy
      </Button> */}
      <Button
        variant="outlined"
        className="AdminForm__SecondaryButton"
        onClick={exitExportMode}
      >
        Go back
      </Button>
      <Button
        variant="contained"
        className="AdminForm__Button"
        onClick={toBeShipClicked}
      >
        To be ship
      </Button>
    </>
  ) :
    <>
      <div
        className="AdminForm__DummyButton"
      >
        Dummy Butto
      </div>
      <Button
        variant="contained"
        className="AdminForm__Button"
        onClick={callExportData}
      >
        Export
      </Button>
    </>
    ;

  return (
    <div className="AdminForm">
      <div className="AdminForm__FormContainer">
        <TextField
          id="AdminName"
          className="AdminForm__TextField"
          label="ผู้รับ"
          variant="standard"
          onChange={onNameChange}
        />
        <TextField
          id="AdminPrice"
          className="AdminForm__TextField"
          label="Price"
          type="number"
          variant="standard"
          onChange={onPriceChange}
        />
        <div className="AdminForm__DropdownContainer">
          <Dropdown
            label="Order Status"
            name="orderStatus"
            onSelect={onOrderStatusSelect}
            data={dropdownData}
            currentValue={orderStatus}
            defaultValue={OrderStatus.all}
          />
          <IconHover icon={{ icon: <ShoppingCartIcon />, name: "To be Order" }} onClick={() => setOrderStatus(OrderStatus.toBeOrder)} />
          <IconHover icon={{ icon: <LocalShippingIcon />, name: "To be Ship" }} onClick={() => setOrderStatus(OrderStatus.toBeShip)} />
        </div>
        {buttons}
      </div>
      {responseTable}
      {pageComponent}
      {isLoading && <Overlay />}
      {isLoading && <LoadingAnimation className="admin" />}
    </div>
  );
};

export { AdminForm };
