// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginForm {
  display: flex;
  flex-direction: column;
}
.LoginForm__Text {
  text-align: center;
  font-style: italic;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/LoginPage/LoginForm/LoginForm.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,kBAAA;EACA,kBAAA;EACA,iBAAA;AADJ","sourcesContent":["@import \"../../shared/common.scss\";\n\n.LoginForm {\n  display: flex;\n  flex-direction: column;\n\n  &__Text {\n    text-align: center;\n    font-style: italic;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
