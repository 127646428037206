import { CakeType } from "constants/CakeType";
import { MooncakeModel } from "shared/utils/callServer/type";

export const mockData: MooncakeModel[] = [
  {
    id: 1,
    name: "ทุเรียนหมอนทอง",
    imageUrl: "https://img2.pic.in.th/pic/mt1.jpeg",
    price: 119,
    itemAvailable: 20,
    info: "เป็นไส้ที่ทำมาจากพุทราจีนกวน รสออกหวานจากผลไม้กวน ตัดหวานด้วยไข่แดงเค็มตรงกลาง 🤤 เพิ่มความกรุบกรอบเล็กน้อยด้วยเมล็ดแตงโม 🍉",
    type: CakeType.Piece,
    isSellOnWeb: true,
  },
  {
    id: 2,
    name: "ขนมชาววัง",
    imageUrl: "https://img2.pic.in.th/pic/mt1.jpeg",
    price: 119,
    itemAvailable: 20,
    info: "เป็นไส้ที่ทำมาจากพุทราจีนกวน รสออกหวานจากผลไม้กวน ตัดหวานด้วยไข่แดงเค็มตรงกลาง 🤤 เพิ่มความกรุบกรอบเล็กน้อยด้วยเมล็ดแตงโม 🍉",
    type: CakeType.Set,
    isSellOnWeb: false,
  },
  {
    id: 3,
    name: "แปดเซียน",
    imageUrl: "https://img2.pic.in.th/pic/mt1.jpeg",
    price: 92,
    itemAvailable: 20,
    info: "",
    type: CakeType.Piece,
    isSellOnWeb: true,
  },
  {
    id: 4,
    name: "เกาลัด แมคคาดาเมียร์",
    imageUrl: "https://img2.pic.in.th/pic/mt1.jpeg",
    price: 111,
    itemAvailable: 20,
    info: "",
    type: CakeType.Piece,
    isSellOnWeb: true,
  },
  {
    id: 5,
    name: "งาดำไข่เดี่ยว",
    imageUrl: "hhttps://img2.pic.in.th/pic/mt1.jpeg",
    price: 111,
    info: "",
    itemAvailable: 20,
    type: CakeType.Piece,
    isSellOnWeb: true,
  },
  {
    id: 6,
    name: "คัสตาร์ดลูกเล็ก",
    imageUrl: "https://img2.pic.in.th/pic/mt1.jpeg",
    price: 55,
    itemAvailable: 80,
    info: "",
    type: CakeType.SmallPiece,
    isSellOnWeb: false,
  },
  {
    id: 7,
    name: "โหงวยิ้งไข่คู่",
    imageUrl: "https://img2.pic.in.th/pic/mt1.jpeg",
    price: 111,
    itemAvailable: 20,
    info: "",
    type: CakeType.Piece,
    isSellOnWeb: true,
  },
  {
    id: 8,
    name: "เต้าซาดำไข่ (ถั่วแดง)",
    imageUrl: "https://img2.pic.in.th/pic/mt1.jpeg",
    price: 111,
    itemAvailable: 20,
    info: "",
    type: CakeType.Piece,
    isSellOnWeb: true,
  },
  {
    id: 9,
    name: "ชาเขียว ถั่วแดง",
    itemAvailable: 20,
    imageUrl: "https://img2.pic.in.th/pic/mt1.jpeg",
    price: 111,
    info: "",
    type: CakeType.Piece,
    isSellOnWeb: true,
  },
  {
    id: 10,
    name: "ผลไม้ นัท California",
    imageUrl: "https://img2.pic.in.th/pic/mt1.jpeg",
    price: 111,
    info: "",
    itemAvailable: 20,
    type: CakeType.Piece,
    isSellOnWeb: true,
  },
  {
    id: 11,
    itemAvailable: 20,
    name: "ลูกบัว",
    imageUrl: "https://img2.pic.in.th/pic/mt1.jpeg",
    price: 111,
    info: "",
    type: CakeType.Piece,
    isSellOnWeb: true,
  },
  {
    id: 12,
    name: "ลูกบัวไข่",
    imageUrl: "hhttps://img2.pic.in.th/pic/mt1.jpeg",
    price: 111,
    info: "",
    itemAvailable: 20,
    type: CakeType.Piece,
    isSellOnWeb: true,
  },
  {
    id: 13,
    name: "พุทราจีนไข่",
    imageUrl: "https://img2.pic.in.th/pic/mt1.jpeg",
    itemAvailable: 20,
    price: 111,
    info: "",
    type: CakeType.Piece,
    isSellOnWeb: true,
  },
  {
    id: 14,
    name: "หมากรุก ทุเรียน ลูกบัว",
    imageUrl: "https://img2.pic.in.th/pic/mt1.jpeg",
    price: 111,
    itemAvailable: 20,
    info: "",
    type: CakeType.Set,
    isSellOnWeb: true,
  },
];
