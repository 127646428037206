import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TextInfo } from "shared/Component/TextInfo/TextInfo";
import { goToHomePage } from "shared/utils/historyUtils";
import { OrderSummary } from "shared/Component/OrderSummary/OrderSummary";
import { PaymentPageProps } from "PaymentPage/PaymentPage";
import { CollapseBox } from "shared/Component/CollapseBox/CollapseBox";
import { AddressSummary } from "shared/Component/AddressSummary/AddressSummary";
import { CommonPage } from "shared/Component/CommonPage/CommonPage";
import "./ThankYouPage.scss";
import { routeName } from "config/routeConfig";
import { IconLink } from "shared/Component/IconLink/IconLink";
import { FormButton } from "shared/Component/FormButton/FormButton";

type Props = RouteComponentProps;

const ThankYouPageComponent: React.FC<Props> = (props) => {
  const [pageProps, setPageProps] = React.useState<PaymentPageProps>();

  React.useEffect(() => {
    if (!props.location.state) {
      goToHomePage(props.history);
      return;
    }
    setPageProps(props.location.state as PaymentPageProps);
  }, [props.location, props.history]);

  if (!pageProps) {
    return null;
  }

  const goToNextPage = () => {
    props.history.push({
      pathname: routeName.TrackingPage,
    });
  }

  return (
    <CommonPage headerText="ขอบคุณค่ะ 🙏">
      <>
        <div className="ThankYouPage__Title">
          ทางร้านได้รับคำสั่งซื้อของท่านเรียบร้อยแล้ว
        </div>
        <div className="ThankYouPage__Text">
          สินค้าจะถูกจัดส่งภายใน 2-3 วัน
        </div>
        <div className="ThankYouPage__Text">
          สอบถามเพิ่มเติม:
          <span className="ThankYouPage__SocialLink">
            <IconLink
              link="https://lin.ee/m8D6X9a"
              icon="https://img2.pic.in.th/pic/line-logo3d5554c0eb57ef16.png"
              text=""
              dark={true}
            />
            <IconLink
              link="https://www.facebook.com/ShangarilaMoonCake"
              icon="https://img2.pic.in.th/pic/fb-logo-1.png"
              text="ShangarilaMoonCake"
              dark={true}
            />
          </span>
        </div>
        <TextInfo
          title="หมายเลขสั่งซื้อ"
          text={pageProps.orderNumber}
          copyText={pageProps.orderNumber}
        />
        <CollapseBox title="ดูรายการสั่งซื้อ">
          <OrderSummary
            mooncakeData={pageProps.mooncakeData}
            amount={pageProps.amount}
            shippingFee={pageProps.shippingFee + pageProps.extraCost}
          />
        </CollapseBox>
        <CollapseBox title="ตรวจสอบที่อยู่">
          <AddressSummary address={pageProps.address} />
        </CollapseBox>
        <FormButton text="ติดตามสถานะการจัดส่ง" onClick={goToNextPage} />
      </>
    </CommonPage>
  );
};

const ThankYouPage = withRouter(ThankYouPageComponent);
ThankYouPage.displayName = "ThankYouPage";

export { ThankYouPage };
